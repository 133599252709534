.sectionTitle {
  composes: brand32 from '../../../../styles/typeography.css';
  text-align: center;
}

.sectionBodyCopy {
  max-width: 48em;
  composes: ui14Regular from '../../../../styles/typeography.css';
  color: var(--colorGray7);
  text-align: center;
}

.mapSection {
  padding-top: 4rem;
  padding-bottom: 6rem;
}

.mapSectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mapImage {
  margin-bottom: 2rem;
}

@media (--viewportTabletMin) {
  .mapImage {
    max-width: 48rem;
    object-fit: cover;
  }
}
